const performanceService = http => {
  const endpoint = `${process.env.VUE_APP_API_BASE_URL}bridge/`

  const get = () => {
    const url = `${endpoint}list`
    return http.get(url).then(data => data.data)
  }

  const post = data => http.post(`${endpoint}store`, data)

  return {
    get,
    post,
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  performanceService,
}
