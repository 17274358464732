<template>
  <b-card :title="`${$t('performance.title')}`">
    <b-card-text>
      <div class="mb-1">

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modalAdd
          variant="primary"
        >
          <feather-icon
            icon="PlusSquareIcon"
            class="mr-50"
          />
          <span>{{ $t('performance.button_new') }}</span>
        </b-button>
      </div>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'date'">
            {{ props.row.date.split('T')[0] | moment('DD/MM/YYYY') }}
          </span>

          <span v-else-if="props.column.field === 'percentage'">
            {{ props.row.percentage }} %
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>

    </b-card-text>

    <b-modal
      id="modalAdd"
      centered
      :title="`${$t('performance.form.title')}`"
      hide-footer
    >
      <b-card-text>
        <validation-observer ref="addValidation">
          <b-form @submit.prevent>
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                name="performance-date"
                rules="required"
              >
                <label>{{ $t('performance.form.date') }}: *</label>

                <b-form-datepicker
                  v-model="newItem.date"
                  name="performance-date"
                  :date-formatter="formatter"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                name="performance-percentage"
                rules="required"
              >
                <label>{{ $t('performance.form.percentage') }}: *</label>
                <b-form-input
                  ref="percentage"
                  v-model.lazy="newItem.percentage"
                  v-money="money"
                  name="performance-percentage"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="`${$t('performance.form.percentage')}`"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="text-center">
              <b-button
                variant="primary"
                size="lg"
                type="submit"
                @click="validationFormNew"
              >
                {{ $t('performance.form.button_submit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import 'vue-good-table/dist/vue-good-table.css'

import {
  BForm,
  BFormGroup,
  BFormDatepicker,
  BButton,
  BCard,
  BModal,
  BCardText,
  BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { performanceService } from '@/services/performance'
import { VMoney } from 'v-money'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BForm,
    BFormGroup,
    BFormDatepicker,
    BModal,
    BButton,
    BCard,
    BCardText,
    BFormInput,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: ' %',
        precision: 10,
        masked: false, /* doesn't work with directive */
      },
      required,
      performanceService: performanceService(this.$http),
      dir: false,
      newItem: {
        date: new Date(),
        percentage: 0,
      },
      rows: [],
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('performance.form.date'),
          field: 'date',
        },
        {
          label: this.$t('performance.form.percentage'),
          field: 'percentage',
        },
      ]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    this.listItens()
  },
  methods: {
    formatter(d) {
      return d.toLocaleDateString()
    },
    async listItens() {
      const resultado = await this.performanceService.get()

      this.rows = resultado.data
    },
    validationFormNew() {
      this.$refs.addValidation.validate().then(async success => {
        if (success) {
          const percentageFinalValue = this.newItem.percentage
            .replace(/\.*/g, '')
            .replace(/ %/g, '')
            .replace(',', '.')

          console.log(this.newItem.date)

          const data = await {
            date: this.newItem.date,
            percentage: percentageFinalValue,
          }

          console.log(data)

          try {
            await this.performanceService.post(data)

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Informação registrada com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            this.$bvModal.hide('modalAdd')
            this.listItens()

            this.newItem = {
              date: new Date(),
              percentage: 0,
            }
            this.$refs.percentage.$el.value = ''
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.data.Message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
  },
}
</script>

<style>

</style>
